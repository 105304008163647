//
// Base -> Typography
//
// Use this file for site-wide typographic
// adjustments, including web fonts and headings.
// --------------------------------------------------


body * {
	font-family: effra, sans-serif;

	p,
	ul li {
		@include regtext(2);
		color: $color-text-neutral;
	}
}

h1,
.h1,
h1 span,
.h1 span {
	@include heading(1);
	color: $color-global-teal500;

	@media (max-width: $screen-sm-max) {
		font-size: 30px;
		line-height: 1.07;
	}
}

h2,
.h2 {
	@include heading(2);

	@media (max-width: $screen-sm-max) {
		font-size: 24px;
		line-height: 1.16;
	}
}

h3,
.h3 {
	@include heading(3);

	@media (max-width: $screen-sm-max) {
		font-size: 18px;
		line-height: 1.26;
	}
}

h4,
.h4 {
	@include heading(4);

	@media (max-width: $screen-sm-max) {
		font-size: 18px;
		line-height: 1.1;
	}
}

h5,
.h5 {
	@include heading(5);

	@media (max-width: $screen-sm-max) {
		font-size: 15px;
		line-height: 1.25;
	}
}

.h2,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
	font-weight: normal;
	color: $color-global-teal500;
	font-family: effra, sans-serif;
}

p.narrow,
p.intro,
p.narrow.intro {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: $color-text-neutral;
	margin-bottom: 50px;
}

.email-link:before,
.fax-link:before,
.html-block .email-link:before,
.html-block .fax-link:before,
.html-block .tel-link:before,
.html-block .web-link:before,
.html-block a.email-link:before,
.html-block a.fax-link:before,
.html-block a.tel-link:before,
.html-block a.web-link:before,
.html-block a.blog__author-linkedin:before,
.tel-link:before,
.web-link:before,
.blog__author-linkedin:before,
a.email-link:before,
a.fax-link:before,
a.tel-link:before,
a.web-link:before,
.main-content a.blog__author-linkedin:before {
	border: none;
	top: 11px;
	margin-right: 0;
}

a, .html-block a {
	text-decoration: none;
	color: $ac-anchorlink;
	font-weight: 700;

	&:hover,
	&:focus,
	&:active {
		color: $color-text-link-hover;
		text-decoration: underline;
	}

	&.tel-link,
	&.email-link,
	&.fax-link,
	&.web-link,
	&.blog__author-linkedin {
		color: $color-global-blue500;
		font-weight: 700;
		text-decoration: underline;
		display: inline;
		min-height: auto;

		&:hover {
			color: $color-global-blue400;
			text-decoration: underline;
		}
	}

	&.tel-link {
		&:before {
			background: url(/assets/images/tel-icon-ac.svg) no-repeat 35% 35%;
		}

		.accordion__body & {
			color: $c-tabs-secondary;
		}
	}

	&.email-link {
		&:before {
			background: url(/assets/images/email-icon-ac.svg) no-repeat 35% 35%;
		}

		.accordion__body & {
			color: $c-tabs-secondary;
		}
	}
}

.html-block a.btn-default:hover {
	text-decoration: none !important;
}

.html-block a.btn-primary:hover {
	text-decoration: none !important;
}

.ac-text-left {
	text-align: left;
}
