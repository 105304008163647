.header {
	.wealth-login__placeholder {
		.btn {
			position: relative;
			top: 0 !important;

			&:hover,
			&:focus
			&:active {
				background: $c-button-light-blue;
			}
		}
	}

	.header {
		.site-search {
			@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				width: 0%;
				top: 3px !important;
			}

			&__btn {
				@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
					background-position: 100% 0;
					width: 25px;
					right: 10px;
				}
			}

			&__input {
				background: $ac-main-search-background-color;
				color: $ac-main-search-color !important;
			}
		}

		&__logo {
			@media (min-width: $screen-sm-min) {
				top: 0;
				position: relative !important;
				z-index: 1;
			}
		}

		&__logo > img {
			position: relative;
			width: 150px;
			padding: 15px 0;
		}

		@media (max-width: $screen-xs-max) {
			&__logo > img {
				position: relative;
				top: 0;
			}
		}

		&-top {
			@media (min-width: $screen-sm-min) {
				padding: 0;
				display: flex;
				align-items: center;
				min-height: 100px;

				.row .ac-mobile-nav {
					display: none;
				}
			}

			@media (max-width: $screen-xs-max) {
				padding: 15px 20px;
				min-height: 72px;

				.container:nth-child(1) {
					min-height: auto;
				}
			}

			.row {
				display: flex;
				align-items: center;
			}

			&__info {
				> p {
					color: #fff !important;
				}
				&.anchor-align {
					text-decoration: none;
					color: #fff;

					&:hover {
						color: inherit;
					}
				}
				&:empty {
					display: none;
				}
			}
			&__info a.anchor-align,
			.header-bottom span.language-selector__selected {
				font-family: effra, sans-serif;
			}
			.canaccord-external-links__wrapper {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				@media (max-width: $screen-xs-max) {
					display: none;
				}
			}
			.tertiary__link {
				font-weight: 400 !important;
			}
		}
		&-bottom {
			background-color: $ac-search-bg;

			@media (min-width: $screen-sm-min) {
				.container {
					position: relative;
					.site-search {
						@media (min-width: $screen-sm-min) {
							position: absolute;
							top: 0px;
						}
					}
				}
			}

			.site-search  {
				> .site-search__input {
					@media (min-width: $screen-sm-min) {
						padding: 4px 40px 4px 15px;;
					}
				}
			}

			.site-search {
				width: auto !important;
				height: 72px;
				display: flex !important;
				align-items: center;
				right: 34px;

				@media(max-width: $screen-sm-max) {
					right: 25px;
				}

				@media(max-width: $screen-xs-max) {
					display: none !important;
				}

				&__input {
					position: initial !important;
					top: initial !important;
					border: 1px solid $ac-main-search-border-color !important;
					color: $ac-main-search-color;
					background-color: $ac-search-bg;
					max-width: 224px;
					height: 40px !important;

					@media (min-width: $screen-sm-min) {
						margin-right: -40px;
					}

					@media (min-width: $screen-md-min) {
						margin-right: -49px;
					}

					&::placeholder {
						color: $ac-main-search-color !important;
						font-size: 16px;
						font-weight: 100;
					}

					&:active,
					&:focus {
						outline: 0;
						border-bottom: 1px solid $ac-search-bg;
					}
				}

				&__btn {
					background: none;
					mask-image: url($imgPath + "search-icon-ac.svg");
					mask-repeat: no-repeat;
					mask-position: 50%;
					-webkit-mask-image: url($imgPath + "search-icon-ac.svg");
					-webkit-mask-repeat: no-repeat;
					-webkit-mask-position: 50%;
					background-color: $color-global-blue400;
					color: transparent;
					width: 30px;
					height: 30px;
					display: inline-block;
					position: relative;
					top: 0 !important;
					right: -10px !important;

					@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
						background-position: 100% 0;
						top: 0 !important;
						right: 17px;
					}

					@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
						right: -13px;
					}

					&:hover,
					&:focus {
						background-color: $color-text-primary;
					}
				}
			}
		}
	}
	.external-links {

        &--mobile {
            @media (max-width: $screen-xs-max) {
                margin: 20px 0;

				.btn {
					color: $c-white !important;
					background-color: $color-global-neutral600;
				}

				.btn:after {
					background-color: $color-global-blue400;
					border-color: transparent !important;
				}
            }
        }
	}
}

.language-selector__list li input.language-selector__link {
	font-family: effra, sans-serif;
}
