﻿
.footer {
	&-top {
		background-color: $footer-top-background-color;
		color: $c-footer-link;
		padding: 22px 0;

		.footer-logo img {
			max-width: 100%;
			height: auto;
		}
	}

	&-links a {
		@include font-effra(300);
		color: $c-footer-link;

		&:hover,
		&:focus,
		&:active {
			color: $c-footer-link;
			text-decoration: underline;
		}
	}

	&-links__heading {
		margin-bottom: 15px;

		a {
			@include font-effra(400);
			color: $c-footer-link;
		}
	}
}

.footer-social-links {
	list-style-type: none;

	&--item {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: $color-text-neutral-inverse;
		border-radius: 50%;
		margin-right: 8px;
		width: 32px;
		height: 32px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.footer-bottom {
	background-color: $footer-bottom-background-color;
	@include boldtext(3.5);

	.pull-left {
		@include boldtext(3.5);
	}

	.tertiary {
		@media (min-width: $screen-sm-min) {
			float: right;
		}

		&__link {
			color: $color-text-primary;
			@include boldtext(3.5);

			&:hover,
			&:focus,
			&:active {
				color: $color-text-link-hover;
				text-decoration: underline;
			}
		}

		&__item {
			&:not(:last-child):after {
				content: "|";
				display: inline-block;
				color: $color-global-neutral200;
				margin-right: 11px;
				padding-left: 11px;
			}
		}
	}
}

.social-icons {
	padding: 0;
	font-size: 0;
	color: $c-white;

	&:hover {
		text-decoration: none;
	}

	&--twitter:after,
	&--youtube:after,
	&--linkedin:after {
		vertical-align: middle;
	}

	&--twitter:after {
		@include fa-icon($fa-var-twitter, 16px, "brands");
		color: #1DA1F2 !important;
	}

	&--youtube:after {
		@include fa-icon($fa-var-youtube, 16px, "brands");
		color: #c4302b !important;
	}

	&--linkedin:after {
		@include fa-icon($fa-var-linkedin, 16px, "brands");
		color: #0e76a8 !important;
	}
}
