//
// Base -> Variables
//
// Use this file to customise Bootstrap variables
// ahead of compilation.
// (See vendor/bootstrap/_variables.scss)
// --------------------------------------------------

$imgPath: '/assets/images/';

// Adam Co

// Global Pallete Colours
$color-global-teal300: #D8E3E9;
$color-global-teal500: #89adbc;
$color-global-purple500: #29307c;
$color-global-purple300: #EAEAF2;
$color-global-orange500: #e56a54;
$color-global-blue500: #006385;
$color-global-blue400: #00b5ef;
$color-global-green500: #00AB8E;
$color-global-neutral100: #ffffff;
$color-global-neutral200: #e5e5e5;
$color-global-neutral300: #d8d8d9;
$color-global-neutral400: #5d5e65;
$color-global-neutral500: #3b3c43;
$color-global-neutral600: #000000;


// Colour Palette Colours

$c-navbar-bg: #695e4a;
$borderColor: #fff;
$c-brand-orange: $c-orange;
$c-moonlight-teal: #006385;
$bgColor: #da8659;
$c-lock-btn-Bgcolour: #fff;
$c-brand-dark-blue: #00234c;
$c-mid-grey: #b1b4b5;
$c-link-visited-grey: #6C7375;
$c-white: #fff;


$c-midnight: #191718;
$c-midnight-moon: #293244;
$c-midnight-rain: #0a161e;
$c-main-nav-bg: #231f20;
$c-darkblue: $c-main-nav-bg;
$c-silver-bullett: #b5ccd9;
$c-left-nav-active: #006385;
$c-slate: #5e97b0;
$c-grape: #293244;
$c-lavendar: #005596;
$c-lightLavendar: #cdd9e7;
$c-buttonblue: #008099;
$c-button-light-blue: #00b5ef;
$c-paleblue: #04bfd8;
$c-near-black: #040404;
$c-off-white: #f0f0f0;

$tooltip-opacity: 10 !default;

$c-hr: #d9dad5;
$c-btn-primary: $c-orange;
$c-btn-secondary: $c-lightblue;

$c-btn-default: $c-lightblue;

$borderColor: white;

$c-document-download-link: $c-lightblue;

$c-heading: #80a1b6;
$c-main-text: #695e4a;
$c-top-nav: #676c73;
$c-advisor-profile-link: $color-global-blue500;
$c-blog-profile-link: $color-global-blue500;
$c-footer-link: #fff;
$c-breadcrumb: #495b67;

$c-grey-opaque: rgba(118, 122, 128, 0.7490196078431373);

$c-lighter-slate: #81888e;
$c-slate: #62656A;
$c-lightslate: #547992;
$c-light-slate: #547992;
$c-tabs-primary: #005596;
$c-tabs-secondary: #80a1b6;
$c-tabs-tertiary: #2e2b27;

$show-left-nav-arrows: true;
$show-cta-heading-arrows: false;
$show-read-more: true;
$header-background-color: #89ADBC;

$c-disclaimer-bg: #787878;

// CTA Specific
$cta-standard-bg: white;
$cta-lightgrey-bg: $c-disclaimer-bg;
$cta-royalblue-bg: $c-royalblue;
$cta-green-bg: $c-green;
$cta-bordered-bg: white;

$cta-padding: 30px 34px;
$cta-padding-reduced: 24px 20px;
$cta-padding-sm-min: 45px 36px;

$cta-text-light: white;
$cta-text-dark: $text-color;
$cta-text-heading-light: $cta-text-light;
$cta-text-heading-dark: $c-heading;

$cta-green-text: $cta-text-light;
$cta-royalblue-text: $cta-text-light;
$cta-lightgrey-text: $cta-text-dark;
$cta-lightgrey-heading-text: $cta-text-dark;

$cta-bottom-margin: 30px;
$cta-homepage-stats-size: 42px;
$cta-equalise-bottom-margin: 15px;
$cta-bg-overlay-color: rgba(0, 0, 0, 0.2);

$cta-bordered-border: 1px solid #dad9d6;
$cta-bordered-text: $text-color;

$cta-standard-heading-color: $c-lightblue;

$cta-has-box-shadow: true;
$cta-box-shadow: 0 5px 10px 4px rgba(0,0,0,.12);

// W.T.F ----- Aditional Varibales for Visual Identity changes 2019 - RM
$footer-bottom-background-color: $color-global-neutral100;
$footer-top-background-color: $color-global-teal500;
$header-bottom-background-color: $c-main-nav-bg;
$c-lightblue: $c-moonlight-teal;


// Colour Palette Colours
$color-text-primary: #2a70a9;
$color-text-secondary: #004b87;
$color-text-neutral: $color-global-neutral500;
$color-text-neutral-inverse: $color-global-neutral100;

$color-text-link-default: #004b87;
$color-text-link-hover: $color-global-blue400;
$color-text-link-active: #004b87;

$color-buttonfill-primary-default: $color-global-blue400;
$color-buttonfill-primary-hover: #2a70a9;
$color-buttonfill-primary-active: $color-global-blue400;
$color-buttonfill-secondary-default: $color-global-neutral100;
$color-buttonfill-secondary-hover: $color-global-blue400;
$color-buttonfill-secondary-active: $color-global-neutral100;

$color-inactive-neutral: #cbcccd;
$color-inactive-neutral-inverse: #f1f1f2;

$color-semantic-success: #00ab8a;
$color-semantic-info: #0067a0;
$color-semantic-warning: #ffa300;
$color-semantic-danger: $color-global-orange500;

// custom
$left-nav-blue: #004b87;
$pagination-blue: #004b87;
$pagination-link-active: $color-global-neutral500;
$header-btn-text: $color-global-neutral500;
$accordion-blue: $color-global-blue500;
$header-background-color: $color-global-teal500;
$navlink: #014c87;
$search-border-colour: #f2eae3;

$ac-btn-ffcolor: $color-global-blue400;
$btn-bgcolor: $color-global-blue500;
$btn-bgcolor2: $color-global-blue500;

$ac-breadcrumb: #004b87;

$ac-form-cta-input: $color-global-teal500;
$ac-form-cta-input-text:#75757a;
$ac-form-cta-input-focus: $color-global-blue400;
$ac-form-cta-input-error: $color-global-orange500;
$ac-form-cta-input-label: #38393d;


$ac-social-media-icons: $color-global-neutral600;
$ac-social-media-icons-border: $color-global-neutral200;
$ac-blog-author-bg: $color-global-neutral100;
$ac-blog-cta-title: $color-text-secondary;
$ac-contact-bg: $color-global-purple500;
$ac-header-button-bg: $color-global-blue400;
$ac-header-button-bg-alt: $color-global-neutral100;
$ac-email-button-bg: $color-global-neutral100;
$ac-back-to-top: $color-global-blue400;
$ac-main-search-border-color: $color-global-teal500;
$ac-main-search-background-color: $color-global-neutral100;
$ac-main-search-color: $color-global-neutral400;
$ac-navlink: $color-global-blue500;
$ac-search-bg: $color-global-neutral100;
$ac-breadcrumb: $color-global-blue500;
$ac-left-nav-active: $color-text-neutral;
$ac-button-dark-blue: $btn-bgcolor;
$ac-accordion-bg: $color-global-neutral100;
$ac-btn-bg: $color-global-neutral100;
$ac-secondary-btn-txt: $color-global-neutral100;
$ac-anchorlink: #36749d;
