﻿.pagination, .main-content .pagination {
    padding-left: 0;
    list-style: none;
    margin: 0 auto 35px;
    width: 100%;
    text-align: center;
    display: block;

    &__item {
        display: inline-block;
        vertical-align: middle;
		@include boldtext(1);
		color: $color-global-blue500;
    }

    &__link {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		padding: 0;
		color: $color-global-blue500;

		&.pagination__link--active {
			border: 1px solid $color-global-teal500;
			color: $color-global-neutral600;
		}

        &.pagination__link--prev,
        &.pagination__link--next {
			background: none;
			mask-image: url($imgPath + "pagination-next-ac.svg");
			mask-repeat: no-repeat;
			mask-position: 16px 16px;
			-webkit-mask-image: url($imgPath + "pagination-next-ac.svg");
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: 16px 16px;
			background-color: $color-global-blue400;

			&:hover {
				background-color: $color-text-primary;
			}
        }

        &.pagination__link--prev {
            transform: rotate(180deg);
			mask-position: 16px 10px;
			-webkit-mask-position: 16px 10px;
        }

		&:hover,
		&:focus,
		&:active {
			color: $color-global-blue400;
			text-decoration: none;
		}
    }
}
