﻿ul.document li a {
    padding-left: 40px;
    margin-left: -40px;
}

ul.circle,
.full-width--bg ul.circle {
    padding-left: 0;
    margin-top: 35px;

    li {
        @extend .tick;
        color: $color-text-neutral;
        display: inline-block;
        background: url(/assets/images/list-circle-ac.svg) no-repeat 0 0;
        padding-left: 50px;
        min-height: 50px;
        width: 100%;
        margin-bottom: 30px;
        background-size: 30px !important;
        vertical-align: top;
        padding-top: 0;

        @media(min-width: $screen-sm-min) {
            width: 32.5%;
            padding-right: 40px;
            min-height: 70px;
        }
    }
}

.full-width--bg {
    ul.circle li {
        background: url(/assets/images/list-circle-BW.png) no-repeat 0 0;
        color: #fff;
    }
}

ul.tag li {
    background: url(/assets/images/list-tag-ac.svg) no-repeat 0 0;

	@media (max-width: $screen-xs-max) {
		background-size: 34px;
		padding-top: 0;
	}

    a {
        position: relative;
		@include boldtext(2.5);
		font-weight: 700;
		color: $color-global-blue500;
		box-shadow: none;
		padding-top: 10px;
		text-decoration: underline;

        &:after {
            content: none; //overrides wmuk styling
        }

		&:hover,
		&:focus,
		&:active {
			color: $color-text-link-hover;
			text-decoration: underline;
		}
    }
}

.contact {
    list-style: none;
    padding-left: 0;
    margin: 20px 0 0px;

    @media (min-width: $screen-sm-min) {
        margin: 20px 0 30px;
    }

    li {
        display: inline-block;
        width: 100%;
        margin-bottom: 15px;

        @media (min-width: $screen-sm-min) {
            width: 24.5%;
            margin-bottom: 0;
        }
    }

    &__link {
		@include boldtext(2);
        color:$c-white;
        background: url($imgPath + 'tel-icon-white-ac.svg') no-repeat 0 50%;
        padding-left: 56px;
        display: block;
		margin: 15px 0;

		a {
			@include regtext(2);
			color: $c-white;

			&:hover {
				color: $c-white;
				text-decoration: underline;
			}
		}
    }
}
