﻿.crumb__link:after,
.main-content .crumb__link:after {
    content: url(/assets/images/crumb-chevron-ac.svg);
    display: inline-block;
    margin: 0 7px 0 10px;
    color: $c-midnight-moon;
    font-size: 19px;
    position: relative;
    top: 0;
    font-weight: 500;
}

.crumb__link,
.main-content .crumb__link {
    color: $ac-breadcrumb;
    @include boldtext(4);

	&:hover,
	&:focus,
	&:active {
		color: $color-text-link-hover;
		text-decoration: underline;
	}

    &--active {
        color: $ac-left-nav-active;
        @include regtext(4);
    }
}
