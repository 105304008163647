.carousel {

    @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        min-height: auto !important;
    }


    &__inner {
        background: $c-off-white;
    }

    &__item {
        @media(max-width: 767px) {
            height: auto;
        }
    }

    div.hero-banner.hero-banner--uk {
        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            padding-top: 36%;
            min-height: auto;
        }

        @media(min-width: $screen-md-min)  {
            min-height: 430px;
        }
    }

    .hero-banner > .container {

        @media(max-width: $screen-xs-max) {
            padding: 55px 15px 15px;
        }
    }

	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

    .slick-dots {
        @media(max-width: $screen-xs-max) {
            top: auto;
            bottom: 70px;
        }

        li button:before {
            @media(max-width: $screen-xs-max) {
                border: 2px solid $c-moonlight-teal;
            }
        }
    }

    .slick-next,
    .slick-prev {
        @media(max-width: $screen-xs-max) {
            bottom: 35px;
            top: auto;
        }
    }

    .slick-next {
		background: url($imgPath + "carousel-right-arrow-ac.svg") no-repeat 50%;

        @media(max-width: $screen-xs-max) {
            right: 5px;
        }

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            right: -5px;
            left: auto;
        }

		&:hover {
			background: url($imgPath + "carousel-right-arrow-ac--hover.svg") no-repeat 50%;
		}
    }

    .slick-prev {
		background: url($imgPath + "carousel-left-arrow-ac.svg") no-repeat 50%;

        @media(max-width: $screen-xs-max) {
            left: 5px;
        }

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            left: -5px;
            right: auto;
        }

		&:hover {
			background: url($imgPath + "carousel-left-arrow-ac--hover.svg") no-repeat 50%;
		}
    }
}
