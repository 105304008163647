﻿.main-content .left-nav, .left-nav {

    &__item {
        display: block;

        &--parent > a {
            color: $c-left-nav-active;
			@include boldtext(2);

            &:after {
                position: absolute;
                right: 0;
                top: -3px;
                content: url(/assets/images/arrow-down-black.svg);
                display: inline-block;
            }

			&:hover,
			&:focus,
			&:active {
				color: $color-text-link-hover;
				text-decoration: underline;
			}
        }
    }

    &__link {
		@include boldtext(2);
        color: $c-left-nav-active;

		&:hover,
		&:focus,
		&:active {
			color: $color-text-link-hover;
			text-decoration: underline;
		}

        &--active {
            color: $c-link-visited-grey;

            &:after {
                content: none; //overrides main & wmuk styling
            }
        }
    }

    &__lower {
        padding-left: 20px;
    }
}
