﻿.blog,
.full-width .blog {
    &__cta {
        position: relative;
        box-shadow: none;
    }

	&__info {
        @include regtext(3.5);
        color: $color-text-neutral;
        margin: 15px 0;
        display: block;

        a {
            text-decoration: none;
            color: $color-global-blue500;
			font-weight: 400;

			&:hover,
			&:focus,
			&:active {
				color: $color-text-link-hover;
				text-decoration: underline;
			}
        }
    }

    &__author {
        background-color: $c-white;
		border: 1px solid $color-global-purple300;

		h2 {
			@include heading(4);
			color: $color-global-neutral500;
		}

		&-job {
			@include boldtext(2);
		}
    }

	&__cta-title + p {
		margin-bottom: 8px;
	}

    &__cta-title a,
    &__cta-title a.muted-link {
        color: $color-global-blue500;
        text-decoration: none;
    }

	&__cta-title a.muted-link {
		@include heading(5);

		&:hover,
		&:focus,
		&:active {
			color: $color-text-link-hover;
			text-decoration: underline;
		}
	}

    &__cta-body {
        .read-more,
        .read-more-toggle {
			// @include boldtext(2);
			font-weight: 700;
            color: $color-global-blue500;
            box-shadow: none;
            padding-bottom: 0;
            text-decoration: underline;

			&:hover,
			&:focus,
			&:active {
				color: $color-text-link-hover;
				text-decoration: underline;
			}
        }

        .read-more:after {
            content: none; //overrides wmuk styling
        }
    }
}

.blog__author.blog__author--detail {
    background-color: $c-white;
	border: 1px solid $color-global-purple300;
}

.blog .blog__author.profile-details-contact-details,
.blog__author-contact-details,
.full-width .blog .blog__author.profile-details-contact-details,
.full-width .blog__author-contact-details {
    padding-top: 0;
    a{
        float: right;
    }
    > .blog__author-email {
        margin-bottom: 20px;
        color: #fff;
    }
}

.main-content .blog__author-contact-details,
.main-content .full-width .blog__author-contact-details {
	.blog__author-email,
	.blog__author-email:hover,
	.blog__author-email:active,
	.blog__author-email:focus {
		font-weight: 400;
	}
}

.main-content .email-link.blog__author-email,
.main-content .full-width .email-link.blog__author-email {
	display: inline;
	text-decoration: underline;

	&:hover,
    &:active,
    &:focus {
		text-decoration: underline;
	}
}


.email-link.blog__author-email,
.tel-link.blog__author-tel,
.main-content .blog__author-linkedin,
.main-content .full-width .blog__author-linkedin {
    background-color: transparent;
    padding: 0;
    font-weight: 700;
    color: $c-blog-profile-link !important;
	text-decoration: underline;
	min-height: auto;
	display: inline;
	margin-bottom: 0;

    &:hover,
    &:active,
    &:focus {
        background-color: inherit !important;
        border: none;
        outline: none;
        color: $color-text-link-hover !important;
        text-decoration: underline;
		font-weight: 700;
    }

    &:after {
        display: none;
    }
}

.main-content,
.main-content .full-width {
	.blog__author-linkedin {
		background: none;
		margin-top: 0;
		padding-top: 0;
		box-shadow: none;

		&:before {
			background: url(/assets/images/linkedin-ac.svg) no-repeat 35% 35%;
			content: "";
			display: inline-block;
			border-radius: 50%;
			position: relative;
			width: 30px;
			height: 30px;
		}
	}
}

.blog__cta-body, .full-width .blog__cta-body {
    color: #101820;
}

.blog__author-details__heading a.muted-link {
    color: $c-advisor-profile-link;

    &:hover,
	&:focus,
	&:active {
		color: $color-text-link-hover;
        text-decoration: underline;
    }
}

.ac-blog__author-job,
.full-width .ac-blog__author-job {
	@include boldtext(2);
}
