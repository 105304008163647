.primary-nav {
	padding-top: 4px !important;
    padding-bottom: 4px !important;

	@media (max-width: $screen-xs-max) {
		padding-top: 0 !important;
	}

	&__list {
		text-align: left !important;
		.btn--lock {
			background-color: transparent;
		}
	}

	&__item.dropdown {
		background: transparent;
	}

	&__item {
		background-color: transparent;
		color: #000;
		a.primary-nav__link {
			font-weight: 400;
			color: $ac-navlink;

			&:hover,
			&:focus,
			&:active {
				color: $color-text-link-hover;
				text-decoration: underline;
			}
		}

		&.dropdown {
			> span.has-children ~ a:after {
				content: "";
				background: url(/assets/images/nav-dropdown-ac.svg)
					no-repeat 0 0 !important;
				margin-left: 14px;
			}
		}
	}

	&__link {
		@include boldtext(2);

		@media (max-width: $screen-xs-max) {
			color: $color-global-blue500;
			padding: 10px 0;

			&:hover,
			&:focus,
			&:active {
				color: $color-text-link-hover;
				text-decoration: underline;
			}
		}
	}

	//dropdown specific
	&__panel {
        max-width: 320px;

		.primary-nav__item {
			background-color: $color-global-teal500;

			@media (max-width: $screen-xs-max) {
				background-color: $c-white;
			}

			&:after {
				border-bottom-color: $color-global-teal500;
			}

			&.dropdown {
				> span.has-children ~ a:after {
					background: none;
				}
			}

			.primary-nav__link {
				@include boldtext(2.5);
				color: $c-white;
				background-color: $color-global-teal500;

				&:hover,
				&:focus,
				&:active {
					color: $c-white;
					text-decoration: underline;
				}

				@media (max-width: $screen-xs-max) {
					background-color: $c-white;
					color: $color-global-blue500;

					&:hover,
					&:focus,
					&:active {
						color: $color-text-link-hover;
						text-decoration: underline;
					}
				}
			}
		}

		ul.primary-nav__item {
			@media (min-width: $screen-sm-min) {
                border-radius: initial;
				padding: 15px 15px 5px;
			}
		}
	}
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: 90%;
    }

	@media (max-width: $screen-xs-max)  {
		width: 320px;
		position: absolute;
        top: 0;
        left: -321px;
        bottom: 0;
        z-index: 1000;
        transition: -webkit-transform .6s;
        transition: transform .6s;
        transition: transform .6s, -webkit-transform .6s;

		&.is-active {
            -webkit-transform: translateX(321px);
            -ms-transform: translateX(321px);
            transform: translateX(321px);
        }

		.dropdown {
			& > .primary-nav__panel {
				width: 320px;
				position: absolute;
				top: 0;
				left: -320px;
				bottom: 0;
				z-index: 1000;
				transition: -webkit-transform .6s;
				transition: transform .6s;
				transition: transform .6s, -webkit-transform .6s;
				background: $c-white;
				margin: 0;
			}

			&.is-active > .primary-nav__panel {
				-webkit-transform: translateX(320px);
				-ms-transform: translateX(320px);
				transform: translateX(320px);
				display: block;
				background-color: $c-white;

				.primary-nav__item {
					background-color: $c-white;

					li:first-child {
						border-bottom: 1px solid $color-global-neutral200;
					}

					li:nth-child(2) {
						margin-top: 15px;
					}

					.primary-nav.links {
						color: $color-global-blue500;
					}
				}
			}
		}
	}

	&_back.primary-nav__link {
        background: url(/assets/images/back-arrow-ac.svg) no-repeat 0% 55%;
        padding-left: 30px;
		padding-bottom: 5px;
        cursor: pointer;
    }
}

@media (min-width: $screen-sm-min) {
	.header-bottom {
		border-bottom: 1px solid $color-global-neutral200;
	}
}
