.social-share,
.html-block .social-share {
	&__heading {
		@include heading(4);
	}

	&__item {
		border: 1.5px solid $color-global-neutral200;
		border-radius: 50%;
		width: 48px;
		height: 48px;

		a {
			width: 100%;
			height: 100%;
		}
	}

    &__linkedin {
        background: url(/assets/images/social-share-linkedin-ac.svg) no-repeat 50% 50%;
    }

    &__twitter {
        background: url(/assets/images/social-share-twitter-ac.svg) no-repeat 50% 50%;
    }

    &__facebook {
        background: url(/assets/images/social-share-facebook-ac.svg) no-repeat 50% 50%;

    }
}
