.primary-nav__item.dropdown > a:after,
.language-selector__selected:after {
    content: '';
    background: url(/assets/images/nav-dropdown-ac.svg) no-repeat 0 0;

}

.primary-nav__link.has-children {
      @media(max-width: $screen-sm-max){
           background: url(/assets/images/nav-dropdown-ac-mobile.svg) no-repeat 100% 50%;
    }
}

.mobile-nav {
    background-color: white;
    position: absolute;
    bottom: 66px;
    left: 0;
    right: 0;
    width: 100%;
    display: none;

    &-open {
        overflow-x: hidden;
    }

    &__close {
        font-size: 18px;
        display: block;
        padding: 15px;
        text-align: center;
    }

    &__inner {
        text-align: center;
        padding: 20px 30px;
        min-height: 150px;

        p {
            font-size: 22px;
            line-height: 1.2;
        }

        a {
            text-decoration: underline;
            font-size: 18px;
            display: block;
        }
    }

    &__toggle {
        display: block;
        width: 25px;
        text-align: center;
        display: block;
        float: right;
        position: relative;
        top: 6px;
        right: 10px;

        @media (min-width: $screen-sm-min) {
            display: none;
        }

        .icon-bar {
            border: 1px solid white;
            margin-bottom: 5px;
            display: block;
            width: 100%;
        }

        &-text {
            color: white;
            font-size: 12px;
        }
    }
}

@media (max-width: $screen-xs-max) {
	.primary-nav__search-wrapper {
        padding: 0;
        text-align: center;
        margin-bottom: 15px;
        position: relative;

        .primary-nav__search {
            width: 100%;
			height: 40px;
            border: 1px solid $ac-main-search-border-color !important;
			border-radius: 0;
			background-color: $ac-search-bg;
			color: $ac-main-search-color;
            padding: 10px 15px;
            padding-right: 45px;
            margin-bottom: 0;

			&:active,
			&:focus {
				outline: 0;
				border-bottom: 1px solid $ac-search-bg;
			}

            &-btn {
				background: url($imgPath + "search-icon-ac.svg") no-repeat 50%;
                width: 30px;
                height: 42px;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 10px;
            }
        }
    }

	ul.primary-nav__list {
        position: relative;
        background-color: $c-white;
		padding-bottom: 20px;
		border-bottom: 1px solid $color-global-neutral200;
    }

	.mobile-nav__fullheight {
        background-color: $c-white;
        height: 100vh;
        .header-top__info {
            padding: 20px 20px 0;
        }
    }

	.primary-nav {
        &_btn-close {
			display: flex;
			justify-content: space-between;
			align-items: center;
            border: none;
            color: $c-white;
            background-color: $color-global-teal500;
            height: 80px;
            width: 110px;
            text-align: center;
            position: absolute;
            right: 0;
            z-index: 78;
			padding: 0 20px;

			&:before {
				content: none;
			}

            &:after {
                content: "";
				display: inline-block;
				background: url($imgPath + "close-icon-ac.svg") no-repeat;
				width: 14px;
				height: 14px;
				margin-left: 15px;
            }
        }

        &.is-active {
            .primary-nav_btn-close {
                left: auto;
                @media (max-width:320px) {
                        left: auto;
                }
            }
        }
    }

    .primary-nav_mobile-header {
        background-color: $header-background-color;
        position: relative;
        height: 80px;
        padding: 15px 10px;

        &-link {
            display: inline-block;
            color: white;
        }
    }
    .primary-nav_mobile-logo {
        max-width: 210px;
        vertical-align: text-bottom;
        margin-left: 10px;
        margin-bottom: 0;
        width: 90px;
    }

}
