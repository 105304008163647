@import 'base/variables';
@import 'base/mixins';


@import 'adamco/base/variables';

@import 'vendor/fontawesome';
@import 'common';

// AdamCo theme specific overrides

@import 'adamco/base/mixins';
@import 'adamco/base/typography';
@import 'adamco/base/buttons';
@import 'adamco/components/tabs';
@import 'adamco/components/language-selector';
@import 'adamco/components/mobile-nav';
@import 'adamco/components/primary-nav';
@import 'adamco/components/full-width';
@import 'adamco/components/blog';
@import 'adamco/components/lists';
@import 'adamco/components/hero-banner';
@import 'adamco/components/cta';
@import 'adamco/components/forms';
@import 'adamco/components/document-downloads';
@import 'adamco/components/accordion';
@import 'adamco/components/cookiebar';
@import 'adamco/components/crumb';
@import 'adamco/components/search';
@import 'adamco/components/pagination';
@import 'adamco/components/left-nav';
@import 'adamco/components/carousel';
@import 'adamco/components/tooltip';
@import 'adamco/components/rss-feed';
@import 'adamco/components/social-share';

@import 'adamco/layout/header';
@import 'adamco/layout/footer';


.back-to-top {
	background: url(/assets/images/back-to-top-paleBlue-UK.png) 50% 50%;
	cursor: pointer;
	background-size: 72%;
	background-repeat: no-repeat;
	background-color: $ac-back-to-top !important;
	border-radius: 100px;
}

.header-top .tertiary__link {
	font-weight: bold;
	text-decoration: none;
}

.com-container {
	padding: 50px 0 0;
}

.full-width--disclaimer {
	background-color: $c-grey-opaque;

	p, a {
		color: $color-global-neutral100;
	}

	&.disclaimer-sticky {
		background-color: $c-disclaimer-bg;
		padding: 20px 0;

		p {
			margin-bottom: 0;
		}
	}
}

@import 'print';
