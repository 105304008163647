﻿.accordion-block {
	margin: 40px 0;
}

.accordion__body {
    background-color: $c-white;

	.panel-body {
		padding: 22px 0;
		color: $color-global-neutral500;

		.tel-link,
		.email-link {
			color: $color-global-blue500;
			font-weight: 700;
			text-decoration: underline;

			&:hover {
				color: $color-global-blue400;
				text-decoration: underline;
			}
		}

		h1, h2, h3, h4, h5 {
			color: $color-global-neutral500;
		}
	}
}

.accordion__panel {
	margin-bottom: 0;

	.accordion__toggle {
		@include heading(5);
		position: relative;
		background-color: $ac-accordion-bg;
		color: $accordion-blue;
		padding: 24px 0;
		border-radius: 0;
		border-bottom: 1px solid $color-global-teal500;

		@media (min-width: $screen-sm-min) {
			@include heading(4);
		}

		&.collapsed {
			border-bottom: 1px solid $color-global-teal300;
		}

		&:after,
		&.collapsed:after {
			content: "";
			background: url(/assets/images/accordion-arrow-down-ac.svg) no-repeat 0 0;
			width: 15px;
			height: 10px;
			background-size: contain;
			top: 35px;
			right: 0;
		}

		&:after {
			transform: rotate(180deg) !important;
		}

		&.collapsed:after {
			transform: rotate(0deg) !important;
		}
	}
}

//Bordered accordion

.bordered-accordion {
    .accordion__toggle {
		@include heading(5);
        background-color:$ac-accordion-bg;
        color: $accordion-blue;
        border-radius: 0;
        background-size: contain;
		padding: 24px 25px;
		border: 2px solid $accordion-blue;

		@media (min-width: $screen-sm-min) {
			@include heading(4);
		}

		&.collapsed {
			border: 2px solid $accordion-blue;
		}

        &.collapsed:after,
        &:after {
            width: 15px;
            height: 10px;
            background-size: contain;
            top: 35px;
			margin-right: 40px;
            background: url(/assets/images/accordion-arrow-down-ac.svg) no-repeat 0 0 !important;
        }

    &:after {
        transform: rotate(180deg) !important;
    }

    &.collapsed:after {
        transform: none !important;
    }
    }
}

